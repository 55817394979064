import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setAuthLocalStorageData } from "../utils/storage";

const AuthContext = createContext(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({
    name: "",
    email: "",
  });
  const [isGuest, setIsGuest] = useState(false);
  const [token, setToken] = useState(
    localStorage.getItem("access_token") || ""
  );
  const navigate = useNavigate();

  const login = (data) => {
    const { name, email, need_email_verify, token } = data;
    const { access_token, refresh_token } = token;

    const user = { name, email, need_email_verify };
    setAuthLocalStorageData(
      "set",
      JSON.stringify(user),
      refresh_token,
      access_token
    );
    setToken(access_token);
    setUser(user);
    setIsGuest(false);
    localStorage.removeItem("isGuest");
    navigate("/workspace");
  };

  // const loginIsGuest = (data) => {
  //   const { access_token, refresh_token } = data;
  //   // const { access_token, refresh_token } = token;

  //   const user = { name, email, need_email_verify };
  //   setAuthLocalStorageData(
  //     "set",
  //     JSON.stringify(user),
  //     refresh_token,
  //     access_token
  //   );
  //   setToken(access_token);
  //   setUser(user);
  //   setIsGuest(false);
  //   localStorage.removeItem("isGuest");
  //   navigate("/workspace");
  // };

  const guestMode = () => {
    setIsGuest(true);
    setUser({ name: "Guest User", email: "" });
    localStorage.setItem("isGuest", "true");
    navigate("/workspace");
  };

  const updateProfileAuth = (data) => {
    if (isGuest) {
      throw new Error("Guest users cannot update profile");
    }
    setUser((prevUser) => ({ ...prevUser, ...data }));
  };

  const register = ({ data, token }) => {
    setUser(data);
    setToken(token);
    setIsGuest(false);
    localStorage.removeItem("isGuest");
    navigate(-1 || "/workspace");
  };

  const logout = () => {
    setUser({ name: "", email: "" });
    setToken("");
    setIsGuest(false);
    setAuthLocalStorageData("remove");
    localStorage.removeItem("isGuest");
    navigate("/login");
  };

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    const storedToken = localStorage.getItem("access_token");
    const storedIsGuest = localStorage.getItem("isGuest");

    if (!storedToken && !storedIsGuest) {
      guestMode();
    } else if (storedIsGuest === "true") {
      guestMode();
    } else if (storedUser && storedToken) {
      setUser(JSON.parse(storedUser));
      setToken(storedToken);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        token,
        user,
        isGuest,
        login,
        logout,
        register,
        updateProfileAuth,
        enterAsGuest: guestMode,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
