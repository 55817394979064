import { lazy } from "react";

const PrivateRoutes = [
  {
    path: "/workspace",
    component: lazy(() => import("../pages/Home/Home")),
    exact: true,
  },
  {
    path: "/workspace/search",
    component: lazy(() => import("../pages/Home/SearchResult")),
    exact: true,
  },
  {
    path: "/product/:id",
    component: lazy(() => import("../pages/Home/Product")),
    exact: true,
  },
  {
    path: "/product/:id/booking",
    component: lazy(() => import("../pages/Home/Booking")),
    exact: true,
  },
  {
    path: "/product/:id/booking-confirm",
    component: lazy(() => import("../pages/Home/BookingConfirm")),
    exact: true,
  },
  {
    path: "/my-bookings",
    component: lazy(() => import("../pages/MyBooking/MyBookings")),
    exact: true,
  },
  {
    path: "/my-bookings/:id",
    component: lazy(() => import("../pages/MyBooking/MyBookingDetails")),
    exact: true,
  },
  {
    path: "/profile",
    component: lazy(() => import("../pages/Profile/Profile")),
    exact: true,
  },
  {
    path: "/profile/detail",
    component: lazy(() => import("../pages/Profile/ProfilDetail")),
    exact: true,
  },
  {
    path: "/profile/change-password",
    component: lazy(() => import("../pages/Profile/ChangePassword")),
    exact: true,
  },
];

export default PrivateRoutes;
